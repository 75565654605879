import React, { useEffect, useState } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";

interface LoadingProps {
    loading: "pending" | "succeeded" | "failed" | "idle"; // 로딩 상태
    loadingMessage: string; // 로딩 중 표시할 메세지
    failedMessage: string; // 로딩 실패 시 표시할 메세지
    action: () => Promise<void>; // 로딩 실패 시 재로딩을 위한 함수
}


/**
 * 로딩 및 재로딩 컴포넌트
 * @param loading 로딩 상태
 * @param loadingMessage 로딩 중 표시할 메세지
 * @param failedMessage 로딩 실패 시 표시할 메세지
 * @param action 로딩 실패 시 재로딩을 위한 함수
 */
const Loading: React.FC<LoadingProps> = ({
    loading,
    loadingMessage,
    failedMessage,
    action
}) => {

    const [isLoading, setIsLoading] = useState({
        loading: false,                     // 로딩 중 여부
        loadingMessage: failedMessage,      // 로딩 중이거나 실패 시 표시할 메세지
    });

    // 문제를 불러오는 중일 때 로딩 표시
    useEffect(() => {
        if (loading !== 'failed') {
            setIsLoading({
                loading: true,
                loadingMessage: loadingMessage,
            });
        } else {
            // 로딩 실패 시
            setIsLoading({
                loading: false,
                loadingMessage: failedMessage,
            });
        }
    }, [loading]);
    
    return (
        <div style={{
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            }}
        >
            {
                isLoading.loading ? (
                    // 로딩 중일 때 CircularProgress 표시
                    <CircularProgress sx={{ opacity: 0.5, marginY: '12px' }} size={16} />
                ) : (
                    // 로딩 실패 시 새로고침 버튼 표시
                    <Tooltip title="새로고침" arrow>
                        <IconButton sx={{ opacity: 0.5, width: 'auto' }} onClick={action} aria-label="Get a new Data" >
                            <RefreshIcon/>
                        </IconButton>
                    </Tooltip>
                )
            }

            {/* 로딩상태 메시지 */}
            <Typography sx={{ opacity: 0.5 }} variant="body2">{isLoading.loadingMessage}</Typography>
        </div>
    );
};

export default Loading;