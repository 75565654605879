import React from "react";
import { List, ListItemButton, Typography, ListItemText } from "@mui/material";
import { ExampleType, QuizModel } from "model/QuizModel";
import CustomMarkdown from 'components/CustomMarkdown/CustomMarkdown';

interface OptionProps {
    currentQuiz: QuizModel;                         // 현재 퀴즈정보
    selectedOption: number;                         // 고른 번호
    handleOptionClick: (option: number) => void;    // 선택한 지문 저장
    isQuiz: boolean;                                // 퀴즈화면이면 true, 해설화면이면 false
    disableClick: boolean;                          // 선지를 클릭했을 때 backgroundColor를 변경시킬지 (현재 문제에서 클릭한 선지가 다음 문제의 선지에 반영되지 않게 하기 위함)
}

// 각 선지에 대한 props
interface ListItemContentProps {
    index: number;      // 선지 번호
    text: string;       // 선지에 들어가는 text
    isQuiz?: boolean;   // 퀴즈화면이면 true, 해설화면이면 false
    isAnswer?: boolean; // 정답인 선지인지
    isNotAnswer?: boolean; // 선택한 선지인지
}

// 선지 컴포넌트
const ListItemContent: React.FC<ListItemContentProps> = ({ index, text, isQuiz = true, isAnswer = false, isNotAnswer }) => (
    <>
        {/* 선지 번호 */}
        <Typography variant="body1" pr={2} pl={0.5} sx={{ display: 'inline' }}>
            {!isQuiz && isAnswer ? 
                '✅' : (isNotAnswer ? '❌' : index + 1)
            }
        </Typography>

        {/* 선지 Text */}
        <CustomMarkdown content={text}/>
    </>
);


const IndexTypoView: React.FC<OptionProps> = ({ currentQuiz, selectedOption, handleOptionClick, isQuiz, disableClick }) => {

    // [해설] 선지 style. 정답 번호의 선지 배경색 변경
    const getBaseStyle = (isAnswer: boolean, isNotAnswer: boolean): React.CSSProperties => ({
        padding: "8px 16px",
        background: isAnswer 
                        ? 'rgba(0, 155, 65, 0.08)' 
                        : (isNotAnswer ? 'rgba(160, 86, 104, 0.08)' : 'transparent'),
    });

    return (
        // 선지 지문 뿌리기
        <List>
            {currentQuiz.examples.map((item: ExampleType, index: number) => {
                // [해설] 정답인 선지인지?
                const isAnswer = item.isAnswer;
                const isNotAnswer = selectedOption === index;
                const baseStyle = getBaseStyle(isAnswer, isNotAnswer);

                return (
                    <React.Fragment key={index}>
                        {isQuiz ? (
                            <ListItemButton
                                onClick={() => handleOptionClick(index)}
                                selected={!disableClick && selectedOption === index}
                            >
                                <ListItemContent index={index} text={item.exampleText} />
                            </ListItemButton>
                        ) : (
                            <ListItemText sx={baseStyle}>
                                <ListItemContent index={index} text={item.exampleText} 
                                    isQuiz={false} isAnswer={isAnswer} isNotAnswer={isNotAnswer}/>
                            </ListItemText>
                        )}
                    </React.Fragment>
                );
            })}
        </List>
    );
}

export default IndexTypoView;