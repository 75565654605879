import TopAppBar from "components/TopAppBar/TopAppBar";
import React from "react";

import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { Card, CardContent, Divider, IconButton, InputBase, Paper, Typography } from "@mui/material";
import { Grid } from "@mui/material";

import NavBar from "components/NavBar";
import Title from "components/Title/Title";
import ButtonWithBackground from "components/ButtonWithBackground/ButtonWithBackground";
import CustomMarkdown from "components/CustomMarkdown/CustomMarkdown";
import QuizArticle from "components/QuizArticle/QuizArticle";
import { QuizModel } from "model/QuizModel";
import { useAppSelector } from "hooks/hooks";
import Loading from "components/Loading/Loading";


const topicTexts = [
  '데이터',
  '사회적 가치',
  '팀과 조직',
  '운영과 자금',
  '비즈니스 모델',
  '영업·마케팅',
  '제품·서비스',
  '시장',
];

interface SearchViewProps {
  executeSearch: (searchText: string) => Promise<void>;
  handleEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearchResult: () => void;
  searchResultText: string;
  searchText: string;
  getSearchResult: any;
  goToSearchPage: (searchText: string, searchType?: string) => void;
};

interface SearchComponentProps {
  loading: "pending" | "succeeded" | "failed" | "idle";
  children: React.ReactElement | React.ReactElement[];
}


// 검색 페이지
const SearchView: React.FC<SearchViewProps> = ({ executeSearch, handleEnterPress, handleInputChange, clearSearchResult, searchResultText, searchText, getSearchResult, goToSearchPage }) => {
  // 로딩 확인을 위한 변수
  const quizLoading = useAppSelector(state => state.searchData.quizLoading);
  const topicLoading = useAppSelector(state => state.searchData.topicLoading);
  const referenceLoading = useAppSelector(state => state.searchData.referenceLoading);


  const SearchComponent = ({loading, children}:SearchComponentProps) => {
    const childCount = React.Children.count(children);

    // 로딩 중일 때
    if (loading === "pending" || loading === "failed") {
      return (
        <Loading 
          loading={quizLoading} 
          loadingMessage="검색 중입니다..." 
          failedMessage="검색에 실패했습니다." 
          action={() => executeSearch(searchText)}
        />
      )
    }

    // 로딩 중이 아닐 때
    else if (childCount === 0) { return <Typography variant="body2" color={'#909090'} sx={{ pt: 2, pb: 4 }}>검색 결과가 없습니다.</Typography> }
    else { return <>{children}</> }


  };




  return (
    <>
      <TopAppBar />

      {/* 검색 Input Box */}
      <Box marginTop={{ xs: '88px', sm: '120px' }} marginBottom={{ xs: '32px', sm: '56px' }}>
        <Paper
          sx={{ p: '12px 24px', display: 'flex', alignItems: 'center', margin: '0 auto', width: '80%', borderRadius: 999 }}
        >
          <InputBase
            sx={{ flex: 1 }}
            placeholder="어떤 문제를 찾고 계신가요?"
            inputProps={{ 'aria-label': '어떤 문제를 찾고 계신가요?' }}
            value={searchText}
            onChange={handleInputChange}
            onKeyDown={handleEnterPress}
          />
          {/* 검색 input이 비어있으면 검색 icon, 차있으면 삭제 icon 노출 */}
          <InputAdornment position="end">
            {searchResultText ? (
              <IconButton onClick={clearSearchResult}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => goToSearchPage(searchText)}>
                <SearchIcon />
              </IconButton>
            )}

          </InputAdornment>
        </Paper>
      </Box>


      {/* 주제 버튼 */}
      <Paper sx={{
        width: '100%',
        maxWidth: '100%',
        borderRadius: '24px 24px 0 0',
        paddingBottom: '48px',
        minHeight: {
          xs: 'calc(100vh - 88px - 32px - 56px - 48px)',
          sm: 'calc(100vh - 120px - 32px - 56px - 48px)'
        },
      }}
      >
        {
          searchResultText ? (
            // 검색 결과가 있을 때
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Title title="검색 결과" description={`"${searchResultText}"에 대한 검색 결과입니다.`} />
              <Divider sx={{ width: '100%' }} />

              <Title title="관련주제" action={() => goToSearchPage(searchResultText, 'topic')}/>
              <SearchComponent
                loading={topicLoading}
                children={
                  <ButtonWithBackground text={getSearchResult.topic} onClick={goToSearchPage} />
                }
              />

              <Title title="문제" action={() => goToSearchPage(searchResultText, 'quiz')}/>
              <SearchComponent
                loading={quizLoading}
                children={
                  getSearchResult.quiz.map((quiz: QuizModel, index: number) => (
                    <Card key={index} sx={{ pb: '14px', mb: 2 }} onClick={() => { }}>
                      <QuizArticle quizItems={quiz} isClickable={true} currentUrl={`/search/${searchResultText}`}/>
                    </Card>
                  )) }
              />


              <Title title="관련 자료" action={() => goToSearchPage(searchResultText, 'reference')}/>
              <SearchComponent
                loading={referenceLoading}
                children={
                  getSearchResult.reference.map((reference: any, index: number) => (
                    <Card key={index} sx={{mb:2}}>
                      <CardContent>
                        <CustomMarkdown content={reference} />
                      </CardContent>
                    </Card>
                  )) }
              />
            </Grid>
          )
            :
            (
              // 검색 결과가 없을 때
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <Title title="주제" description="위계 만다라트를 통해 선정된 대주제로 검색해보세요." />
                <ButtonWithBackground text={topicTexts} onClick={goToSearchPage} />
              </Grid>
            )
        }
      </Paper>

      <NavBar />
    </>
  );
}

export default SearchView;