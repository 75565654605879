import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { topicList } from 'redux/modules/topicList';
import { cardList } from 'redux/modules/cardList';
import { quizCounter } from 'redux/modules/quizCouter';
import userAuth from 'redux/modules/userAuth';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import modal from 'redux/modules/modal';
import isQuiz from 'redux/modules/isQuiz';
import attendance from 'redux/modules/attendance';
import userData from 'redux/modules/userData';
import internetConnect from 'redux/modules/internetConnect';
import myPageData from 'redux/modules/myPageData';
import searchData from 'redux/modules/searchData';
import { migrateState } from 'redux/reduxPersistMigration';

const reducers = combineReducers({
    quizCounter: quizCounter.reducer,
    cardList: cardList.reducer,
    topicList: topicList.reducer,
    modal: modal,
    userAuth: userAuth,
    isQuiz: isQuiz,
    attendance: attendance,
    userData: userData,
    internetConnect: internetConnect,
    myPageData: myPageData,
    searchData: searchData,
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userAuth", "isQuiz", "quizCounter", "cardList", "attendance", "topicList", "userData", "internetConnect, searchData", "myPageData"],
    version: 3,
    migrate: migrateState,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});

// export type RootState = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
export default store;