import { Card, Grid, Pagination, Typography } from "@mui/material";
import Achievements from "components/Achievements/Achievements";
import Loading from "components/Loading/Loading";
import QuizAppBar from "components/QuizAppBar/QuizAppBar";
import QuizArticle from "components/QuizArticle/QuizArticle";
import Title from "components/Title/Title";
import { useAppSelector } from "hooks/hooks";
import { mergedAchievementType } from "model/UserDataModel";
import { SearchResultInterface } from "model/searchDetailModel";
import React, { useEffect, useState } from "react";

interface MYDetailContainerProps {
    contentType: string;
    searchResult: SearchResultInterface<mergedAchievementType[]>;
    currentPage: number;
    handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    executeSearch: (searchPage: number) => void;
};

const MYDetailView: React.FC<MYDetailContainerProps> = ({ contentType, searchResult, currentPage, handlePageChange, executeSearch }) => {
    // content type에 따라 다른 컴포넌트를 나타내기 위한 변수
    const [content, setContent] = useState<React.ReactNode | null>(null);
    const isLoading = useAppSelector((state) => state.userData.loading);
    const quizCards = (currentUrl: string) => {

        // 로딩 중이거나 데이터 받아오기에 실패했을 때
        if (isLoading !== "succeeded") {
            return (
                <Loading
                    loading={isLoading}
                    loadingMessage="문제를 불러오는 중입니다."
                    failedMessage="문제를 불러오지 못했습니다."
                    action={async () => { executeSearch(1); }}
                />
            )
        }

        // 검색 결과를 성공적으로 받아왔을 때
        return(
            searchResult.result.map((quiz: any, index: number) => (
                <Card key={index} sx={{ pb: '14px', mb: 2 }} onClick={() => { }}>
                    <QuizArticle quizItems={quiz} isClickable={false} currentUrl={currentUrl}/>
                </Card>
            ))
        );
    };


    useEffect(() => {
        // content type에 따라 다른 컴포넌트를 나타냄
        if (contentType === "풀었던 문제") {
            // contentType이 푼 문제일 때
            setContent(quizCards(`/MY/history`));
        } else if (contentType === "틀린 문제") {
            // contentType이 틀린문제일 때
            setContent(quizCards(`/MY/mistake`));
        } else if (contentType === "업적") {
            // contentType이 업적일 때
            const referenceCards = <Achievements achievementRes={searchResult.result} pagingSize={36}/>;
            setContent(referenceCards);
        }
    }, [contentType, searchResult, isLoading]);



    return (
        <div>
            <QuizAppBar navType="MY" navKeyword="MY" />
            <Grid
                pt={4}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100%' }}
            >
                <Title title={contentType} />
                {content}

                {
                    searchResult.totalPages > 1 &&
                    <Pagination count={searchResult.totalPages} page={currentPage} onChange={handlePageChange} sx={{ m: '16px 0' }} />
                }
            </Grid>
        </div>
    );
};

export default MYDetailView;