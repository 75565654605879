import { AchievementsProps, mergedAchievementType } from "model/UserDataModel";
import achievementJSON from "../mockData/achievements.json";


/**
 * 업적 데이터에서 필요한 데이터만 추출
 * @param achievements 업적 데이터(id, 클리어 여부만 있는 데이터)
 * @returns 필요한 업적 전체 데이터
 */
const pickAchievementData = (achievements: AchievementsProps[]): mergedAchievementType[] => {
    // 업적 데이터에 클리어 여부 추가
    if (achievements.length > 0) {
        let mergedArray = achievements.map(({achievementsId, isClear}) => {
            const achievement = achievementJSON.achievement.find(a => a.achievement_id === achievementsId);
            if (!achievement) {
                return {
                    index: 0,
                    achievement_id: achievementsId,
                    name: '알 수 없는 업적',
                    description: '',
                    target: 'TOT_PLAY_COUNT',
                    count: 0,
                    isClear: false,
                };
            }
            return { ...achievement, isClear };
        });
        return mergedArray;
    }
    return [];
};

export default pickAchievementData;