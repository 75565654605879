import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserdataType } from 'model/UserDataModel';
import achievementData from "../../mockData/achievements.json";
import { getAchievements } from './API/getAchievements';
import { getQuizPlayed, getWrongQuiz } from './API/getSearchResults';
import { createLoadingReducers } from 'redux/createLoadingReducers';


const initialState: UserdataType = {
    achievements: [],   // 사용자가 달성한 업적의 전체 정보
    loading: "idle",    // 업적 로딩 상태
    likeQuizIds: [],    // 좋아요를 누른 퀴즈ID 리스트
}

// 관련자료 주제 리스트
export const userData = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        
        /** 
         * 새로 추가된 업적을 user업적에 추가하기.
         * 백에서는 업적 id만 반환하기 때문에 achievementData서 전체 데이터를 가져옴
         * 업적들을 리스트로 변환
         */
        setAchievementData(state, action: PayloadAction<string[]>) {
            // 각 업적ID에 해당하는 achievementData의 데이터들을 뽑아내기
            state.achievements = action.payload.length > 0 
                                    ? achievementData.achievement.filter(item => action.payload.includes(item.achievement_id))
                                    : [] ;
        },

        // 좋아요를 누른 퀴즈의 ID 추가
        addLikeQuiz(state, action: PayloadAction<number>) {
            const quizId = action.payload;

            // 중복 여부 확인
            if (!state.likeQuizIds.includes(quizId)) {
                state.likeQuizIds.push(quizId);
            }
        },

        // 좋아요 취소
        removeLikeQuiz(state, action: PayloadAction<number>) {
            state.likeQuizIds = state.likeQuizIds.filter((item) => item !== action.payload);
        },
    },
    extraReducers: (builder) => {
            createLoadingReducers(builder, getAchievements, 'loading');
            createLoadingReducers(builder, getQuizPlayed, 'loading');
            createLoadingReducers(builder, getWrongQuiz, 'loading');
        },
});

export const { setAchievementData, addLikeQuiz, removeLikeQuiz } = userData.actions;

export default userData.reducer;