import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { openModal } from 'redux/modules/modal';
import history from 'utils/history';
import { Update } from 'history';
import { swipeQuiz } from 'utils/swipeQuiz';

interface QuizAppBarProps {
    navType: string;    // 네비게이션 타입
    navKeyword: string | undefined; // 화면에 표시될 네비게이션 키워드
}

/*
* 문제화면 상단 AppBar
*/
const QuizAppBar:React.FC<QuizAppBarProps> = ({navType, navKeyword}) => {
    const isQuiz: boolean = useAppSelector((state) => state.isQuiz.quiz);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const currentRootPath = location.pathname.split("/")[1];
    
    const handleNavigate = () => {
        if (navType === "종료하기"){
            dispatch(
                openModal({
                    modalType: "GoHomeModal",
                })
            );
        } else if (navType === "뒤로가기"){
            navigate(-1);
        } else if (navType === "탐색"){
            navigate(`/search/${navKeyword}`);
        } else if (navType === "MY") {
            navigate(`/my`);
        }
    };
        
    // 안드로이드 뒤로가기 버튼 눌렀을 때의 대응을 위한 코드
    useEffect(() => {

        const unlisten = history.listen((location: Update) => {
            // 뒤로가기 버튼일 시 홈으로 이동
            if (location.action !== "POP") return;

            // 해설 화면에서 홈으로 이동할 시 현재 퀴즈를 다음 퀴즈로 갱신
            if (currentRootPath === "quiz" || currentRootPath === "explanation") {
                swipeQuiz(dispatch, isQuiz);

                history.push(location.location.pathname);
                navigate('/home');
                
            } else if (navType === "탐색") {
                // 탐색 페이지의 하위페이지인 경우
                // 검색한 키워드의 검색결과 페이지로 이동
                history.push(location.location.pathname);
                navigate(`/search/${navKeyword}`);
            }
        });

        return () => {
            unlisten();
        };
    }, [history, dispatch, isQuiz]);

    return (
        <AppBar component="nav">
            <Toolbar>
                <Button aria-label="check answers" variant="text" sx={{ mr: '16px', justifyContent: "flex-start" }} onClick={handleNavigate}>
                    <NavigateBeforeIcon/>
                    {navKeyword}
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default QuizAppBar;