import { useEffect } from 'react';

/**
 * body의 배경색을 변경하는 hook
 * @param color 변경할 배경색
 */
const useChangeBodyColor = (color: string) => {
    useEffect(() => {
        const prevColor = document.body.style.backgroundColor;
        document.body.style.backgroundColor = color;
        return () => {
            document.body.style.backgroundColor = prevColor;
        };
    }, [color]);
};

export default useChangeBodyColor;