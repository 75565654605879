import React from "react";
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { fetchQuiz } from "redux/modules/API/fetchQuiz";
import Loading from "components/Loading/Loading";

/**
 * 퀴즈를 불러올 수 없는 상태일 때 퀴즈 화면 대신 표시
 */
const NoQuiz: React.FC = () => {
    const dispatch = useAppDispatch();
    const currentQList = useAppSelector(state => state.cardList.quizList);
    const isLoading = useAppSelector(state => state.cardList.loading);
    const quizCount = useAppSelector(state => state.cardList.quizList.length);


    const tryGetNewQuiz = async() => {

        // 받아와야 할 퀴즈의 개수
        const quizLength = 2 - currentQList.length;

        // 퀴즈가 이미 2개 이상이면 더 받아오지 않음
        if (quizLength <= 0) return;

        // fetchQuiz.. 퀴즈가 2개 이상이 될 때까지 반복
        for (let i = 0; i < quizLength; i++) {
            await dispatch(fetchQuiz());
        };
    };

    // 서버에서 반환된 결과와 상관없이 redux에 저장된 퀴즈가 없을 때,
    // loading을 failed로 설정    
    return (
        <Loading
            loading={quizCount === 0 ? 'failed' : isLoading}
            loadingMessage="문제를 불러오는 중입니다..."
            failedMessage="문제를 불러오지 못했습니다."
            action={tryGetNewQuiz}
        />
    );
};

export default NoQuiz;