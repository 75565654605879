/**
 * 
 * @param currentPath 현재 url path
 * @returns 현재 url 기준 1depth의 경로를 반환
 */
export const getRootPath = (currentPath: string) => {
    // '/'경로일 경우 home으로 설정
    if (currentPath === "/") {
        return "home";
    }
    
    // 현재 경로의 value를 반환
    return currentPath.split("/")[1];
};