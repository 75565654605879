import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Transition, Variants, motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { addLikeQuiz, removeLikeQuiz } from 'redux/modules/userData';

const LikeButton = () => {
    const dispatch = useAppDispatch();
    const currentQuizId = useAppSelector((state) => state.cardList.quizList[0].quizId);     // 현재 문제의 ID
    const userLikeList = useAppSelector((state) => state.userData.likeQuizIds);             // 유저의 좋아요 리스트 
    const [liked, setLiked] = useState(false);          // 해당 문제에 대해 유저의 좋아요 상태
    const [likeCount, setLikeCount] = useState(0);      // 좋아요 개수(추후 서버에서 받아올 예정)

    // 좋아요 버튼 애니메이션 설정
    const [isAnimating, setIsAnimating] = useState(false);
    const variants:Variants = { initial: { rotate: 0 }, animate: { rotate: 360 } };
    const transition:Transition = {
        duration: 0.5,
        type: 'spring',
        stiffness: 260,
        damping: 20
    };

    // 좋아요 버튼 클릭 시
    const handleLike = () => {
        // 좋아요 버튼 클릭시 화면에 좋아요 상태 반영
        setLiked(!liked);
        setLikeCount(liked ? 0 : 1);

        // 애니메이션 효과
        setIsAnimating(!isAnimating);

        // 유저의 좋아요 리스트에 문제 추가 또는 삭제
        dispatch(liked ? removeLikeQuiz(currentQuizId) : addLikeQuiz(currentQuizId));
    };


    // 퀴즈에 대한 좋아요 여부 확인
    const getQuizLike = (currentQuizId: number): boolean => {
        return userLikeList && userLikeList.includes(currentQuizId);
    };

    // 좋아요 상태 업데이트
    useEffect(() => {
        const likeTF = getQuizLike(currentQuizId);
        setLiked(likeTF);
        setLikeCount(likeTF ? 1 : 0);
    }, [currentQuizId, userLikeList]);

    return (
        <Grid container sx={{ alignItems: 'center' }}>
            <motion.div
                initial={{ rotate: 0 }}
                animate={isAnimating ? "animate" : "initial"}
                variants={variants}
                transition={transition}
            >
                {/* 좋아요 버튼 */}
                <IconButton onClick={handleLike} aria-label="like button" size='small'>
                    {liked ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
                </IconButton>
            </motion.div>
            <Typography variant="body1" color="#acacac">{likeCount}</Typography>
        </Grid>
    );
};

export default LikeButton;
