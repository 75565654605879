import React, { useEffect, useState } from "react";
import SignUpView from "./SignUpView";

import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "firebase-config";
import { useNavigate } from "react-router-dom";

import { signInUser } from "redux/modules/userAuth";
import { useAppDispatch } from "hooks/hooks";
import { getUserStat } from "redux/modules/API/getUserStat";
import useChangeBodyColor from "hooks/useChangeBodyColor";


interface LoginCredentials {
    email: string;      // 유저 이메일
    password: string;   // 유저 비밀번호
};

const SignUpContainer = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState('');
    const [nickname, setNickname] = useState('');
    const [checkBox, setCheckBox] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [errMsg, setErrMsg] = useState('');


    // 각 input에 대한 handle 함수
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNickname(event.target.value);
    };

    const handleIsChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckBox(event.target.checked);
    };

    // 비밀번호 검사
    const validatePassword = () => {
        let isValid = true;
        if (password !== checkPassword) {
            isValid = false;
            setErrMsg("비밀번호를 다시 한번 확인해주세요.");
        }
        
        return isValid;
    }

    // 회원가입 버튼 활성화 여부 결정
    useEffect(()=>{
        const userInfoList = [email, password, checkPassword, nickname]

        // 빈 칸이 있는지 확인
        const isNoEmptyValue = userInfoList.filter(v => v === '').length ? false : true;

        // 빈 칸이 없고, 개인정보 동의까지 했을 때 회원가입 버튼 활성화
        if (isNoEmptyValue && checkBox) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [email, password, checkPassword, nickname, checkBox]);

    // 전역 배경색 설정
    useChangeBodyColor('white');
    
    // 폼 제출 시
    const handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const credentials: LoginCredentials = {email, password};
        
        // 비밀번호 검사
        if(!validatePassword()) return;

        try {
            // 사용자 계정 만들기
            await createUserWithEmailAndPassword(auth, email, password);

            // 사용자 닉네임 설정
            if (auth.currentUser) {
                await updateProfile(auth.currentUser, {
                    displayName: nickname,
                });

                // 사용자 데이터
                await dispatch(signInUser(credentials)).unwrap();

                // 서버에서 사용자 데이터 받아온 후 저장하기
                await dispatch(getUserStat());
                
                // 목표설정 화면으로 이동
                navigate('/purpose-setting');
            }

            // 목표설정 화면으로 이동
            navigate('/purpose-setting');

        } catch (error: any) {
            switch (error.code) {
              case "auth/email-already-in-use": {
                return setErrMsg("사용 중인 이메일입니다.");
              }
              case "auth/invalid-email": {
                return setErrMsg("해당 이메일 사용이 불가능합니다.");
              }
              case "auth/weak-password": {
                return setErrMsg("비밀번호는 6글자 이상이어야 합니다.");
              }
              default : {
                return setErrMsg("잘못된 이메일입니다.");
              }
            }
        } finally {
            setLoading(false);
        }
    };

    return(
        <SignUpView
            loading={loading}
            email={email}
            password={password}
            checkPassword={checkPassword}
            nickname={nickname}
            checkBox={checkBox}
            errMsg={errMsg}
            disabled={disabled}
            setPassword={setPassword}
            setCheckPassword={setCheckPassword}
            handleSubmit={handleSubmit}
            handleEmailChange={handleEmailChange}
            handleNicknameChange={handleNicknameChange}
            handleIsChecked={handleIsChecked}
        />
    )
};

export default SignUpContainer;