import { ActionReducerMapBuilder, AsyncThunk } from '@reduxjs/toolkit';

export const createLoadingReducers = <State, FulfilledActionType, PendingActionType, RejectedActionType>(
  builder: ActionReducerMapBuilder<any>,
  action: AsyncThunk<FulfilledActionType, PendingActionType, { rejectValue: RejectedActionType }>,
  loadingState: keyof State
) => {
  builder
    .addCase(action.fulfilled, (state) => {
      state[loadingState] = 'succeeded';
    })
    .addCase(action.pending, (state) => {
      state[loadingState] = 'pending';
    })
    .addCase(action.rejected, (state) => {
      state[loadingState] = 'failed';
    });
};