import { topics } from "mockData/topics";

type StartTypo = {
    purpose: string;        // 현재 페이지 구분을 위함
    step: string;           // 현재 페이지 단계 (사실상 제목)
    title: string;          // 현재 페이지에 대한 간단한 설명
    description: string[];  // 어떤 것을 선택해야 하는 지에 대한 상세한 설명
    items: string[];        // 선택지
    setDisabled: boolean;   // 다음 버튼 활성화 여부
}

// 회원가입 후 학습목표 입력 페이지 내용
export const startTypo:StartTypo[] = [
    {
        purpose: 'studyPurpose',
        step: '학습목적',
        title: '학습목적을 알려주세요.',
        description: ['StartupQT를 이용하시는 목적이 무엇인가요?', ''],
        items: ['사업준비', '투자창업', '회사업무', '자기계발',],
        setDisabled: true
    }, {
        purpose: 'topic',
        step: '관심분야',
        title: '관심 분야를 알려주세요.',
        description: ['관심 분야를 선택해주시면 학습 추천에 반영해드려요. (복수선택 가능)', ''],
        items: topics.map(topic => topic.topicName),
        setDisabled: true
    }, {
        purpose: 'topicLevel',
        step: '목표수준',
        title: '목표 수준을 설정하세요.',
        description: ['잘 선택하셨어요!', '각 관심분야를 어느 수준까지 학습하고 싶으신가요?'],
        items: [],
        setDisabled: false
    }, {
        purpose: 'dailyGoal',
        step: '일일목표',
        title: '일일 목표를 세워볼까요?',
        description: ['하루에 몇 개의 문제를 풀지 정해 보는 단계예요.', ''],
        items: [],
        setDisabled: false
    }, {
        purpose: 'complete',
        step: '완료',
        title: '이제 모두 준비되었어요.',
        description: ['목표설정이 완료되었어요.', '창업의 꿈에 한 발짝씩 다가가 볼까요?'],
        items: [],
        setDisabled: false
    }
];