import React, { useCallback, useEffect, useState } from "react";
import { BottomNavigationAction, CircularProgress, LinearProgress } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from "hooks/hooks";
import { increaseCount, resetCount } from "redux/modules/quizCouter";
import scrollToTop from "utils/scrollToTop";
import { getOX, waitAndHideOXMarks } from "redux/modules/cardList";
import { openModal } from "redux/modules/modal";
import { turnToExplanation } from "redux/modules/isQuiz";
import { increaseDailyUnitStudy } from "redux/modules/attendance";
import { setAchievementData } from "redux/modules/userData";
import { sendQuizData } from "redux/modules/API/sendQuizData";
import { swipeQuiz } from "utils/swipeQuiz";
import { searchQuiz } from "redux/modules/API/getSearchResults";
import { increaseCurrentSearchedQuiz } from "redux/modules/searchData";
import LikeButton from "components/LikeButton/LikeButton";

/*
* 퀴즈화면의 하단 NavBar
*/
const QuizNav: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cardList = useAppSelector((state) => state.cardList);
    const quizData = useAppSelector((state) => state.quizCounter);
    const userId = useAppSelector((state) => state.userAuth.user?.userId);
    const value: number = quizData.count;
    const isSelected: boolean = cardList.isSelected;
    const MAX: number = quizData.maxCount;
    const MIN: number = 0;
    const normalise: number = ((value - MIN) * 100) / (MAX - MIN);


    // 퀴즈화면이면 true, 해설화면이면 false
    const isQuiz: boolean = useAppSelector((state) => state.isQuiz.quiz);
    
    // 현재 문제 로딩중인지?
    const isLoading: string = useAppSelector((state) => state.cardList.loading);
    const [loading, setLoading] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    // url에 keyword나 id가 있다면 search페이지
    const { keyword, id } = useParams();
    const searchData = useAppSelector(state => state.searchData);

    
    // 해설화면에서 문제화면으로
    const handleNavigateQuiz = async () => {
        try {
            // maxCount에 도달했을 때 분석모달 띄우기
            if (value >= MAX) {
                // 일일 단위학습 수 하나 늘리기
                dispatch(increaseDailyUnitStudy());
                // count 초기화
                dispatch(resetCount());
                dispatch(
                    openModal({
                        modalType: "AnalyzeModal",
                    })
                );
            }

            // 검색페이지면 다음 번호의 문제를 가져온 후 이동
            if(keyword){
                // 다음 문제 ID 가져오기
                dispatch(increaseCurrentSearchedQuiz());
                const quizSearchResponse = await dispatch(searchQuiz({ pageNum: searchData.currentSearchedQuiz, pagingSize: 1, searchText: keyword }));
                const nextSearchQuizId = quizSearchResponse.payload.results.results[0].quizId;

                // 다음 문제로 이동
                navigate(`/search/${keyword}/quizpage/${nextSearchQuizId}`);
                scrollToTop();

                return;
            }

            // 해설 화면에서 다음으로 이동할 시 현재 퀴즈를 다음 퀴즈로 갱신
            await swipeQuiz(dispatch, isQuiz);

            navigate('/quiz');
            scrollToTop();
        } catch (error) {
            alert('문제 받아오기에 실패했습니다. 홈으로 이동합니다.');
            navigate('/');
        }
    };

    // 다음문제 버튼 클릭했을 때 로딩 상태에 따라 다르게 처리
    // 1. 로딩중이 아니면 문제화면으로 이동
    // 2. 로딩중이면 로딩중이라고 표시
    const handleClick = useCallback(() => {
        if (!loading) {
            handleNavigateQuiz();
        } else {
            setIsClicked(true);
        }
    }, [loading]);

    // 클릭했을 때 로딩되기를 기다렸다가 문제화면으로 이동
    useEffect(() => {
      if (isLoading === 'pending') {
        setLoading(true);
      } else if (isLoading === 'succeeded') {
        setLoading(false);
        if (isClicked) {

            handleNavigateQuiz();
            setIsClicked(false);
        }
      }
    }, [isLoading, isClicked, handleClick]);
    

    // 문제화면에서 해설화면으로
    const handleNavigateDescription = async() => {

        // 푼 문제 수를 하나 늘림
        dispatch(increaseCount());

        // 현재상태 변수를 문제에서 해설로 바꾸기
        dispatch(turnToExplanation());

        
        // 검색페이지면 그에 맞는 url로 이동
        if(keyword){
            // 정오 판단 및 OX 표시 애니메이션 출력
            dispatch(getOX(searchData.searchQuizList[0]));
            dispatch(waitAndHideOXMarks());

            navigate(`/search/${keyword}/explanationpage/${id}`);
            scrollToTop();
            
            return;
        }

        // 정오 판단 및 OX 표시 애니메이션 출력
        dispatch(getOX(cardList.quizList[0]));
        dispatch(waitAndHideOXMarks());
        
        // 문제 이력 보내기
        const response = await dispatch(sendQuizData({
            playStartTime : quizData.playStartTime,
            selectExampleId : cardList.selectedIndex,
            isAnswer : cardList.isCorrect,
            quizId: cardList.quizList[0].quizId,
            userId: userId,
        }));

        // 새로운 업적이 있을 때 모달 띄우기
        const achievements = response.payload?.earnAchievementIdList;

        if (achievements!==undefined && achievements.length > 0) {
            // 업적 ID와 실제 업적 데이터 매칭하기
            dispatch(setAchievementData(achievements));

            // 업적모달 열기
            dispatch(
                openModal({
                    modalType: "AchievementsModal",
                })
            )
        };

        // 해설화면으로 이동
        navigate('/explanation');
        scrollToTop();
    };

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'transparent', zIndex: '2' }} elevation={0}>
            {/* 현재 푼 문제 수를 세주는 progress Bar */}
            <LinearProgress variant="determinate" value={normalise} />
            <BottomNavigation
                showLabels
                value={value}
                sx={{ justifyContent: 'right', background: '#EEEDF1', pl: 1.5 }}
            >
                {/* 좋아요 버튼 */}
                <LikeButton />

                {/* 다음문제 혹은 정답확인 버튼 */}
                {!isQuiz ?
                    <BottomNavigationAction
                        icon={loading && <CircularProgress size={20} color="inherit" />}
                        label={!loading && "다음문제"}
                        disabled={loading}
                        sx={{
                            mr: '16px',
                            maxWidth: '80px',
                            fontWeight: '500',
                            color: '#00639B',
                        }}
                        onClick={handleClick}
                    />
                    :
                    // 문제화면일 때 '정답확인' 버튼 노출
                    <BottomNavigationAction
                        label="정답확인"
                        sx={{
                            mr: '16px',
                            maxWidth: '80px',
                            fontWeight: '500',
                            color: isSelected ? '#00639B' : '#BBBBBB',
                        }}
                        onClick={handleNavigateDescription}
                        disabled={!isSelected}
                    />
                }
            </BottomNavigation>
        </Paper>
    );
};

export default QuizNav;