import React, { useState } from "react";
import PurposeSettingView from "./PurposeSettingView";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/hooks";
import { postStudySettings } from "redux/modules/API/postStudySettings";
import { changeDailyGoal } from "redux/modules/myPageData";
import useChangeBodyColor from "hooks/useChangeBodyColor";
import { startTypo } from "mockData/startTypo";
import { topics } from "mockData/topics";

const PurposeSettingContainer = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    // 현재 이미지 및 텍스트의 인덱스 상태
    const [currentIndex, setCurrentIndex] = useState(0);

    // 선택한 버튼의 인덱스
    const [selectedOption, setSelectedOption] = useState([-1]);

    // 선택지가 선택된 상태인지?
    const [isSelected, setIsSelected] = useState(false);

    // 직접설정 용 일일목표 변수
    const [dailyGoal, setDailyGoal] = useState<number>(10);

    // 학습목표 저장용 변수
    const [purpose, setPurpose] = useState({
        studyPurpose: '',
        topic: [],
        topicGoals: [{
            topicId: 0,
            goal: ''
        }],
        dailyGoal: 0,
        topicLevel: [],
    });

    // 전역 배경색 설정
    useChangeBodyColor('white');

    // 학습목표 설정 칩(입문, 발전, 전문) 선택 시
    const handleChipClick = (level: string, index: number) => {
        setIsSelected(true);
        
        // 선택된 level을 저장
        setPurpose(prevPurpose => ({
            ...prevPurpose,
            topicGoals: prevPurpose.topicGoals.map((goal, idx) => idx === index ? { ...goal, goal: level } : goal)
        }));
    }

    // 선택한 버튼의 인덱스와 값을 저장
    const handleOptionClick = (pageIndex: number, option: number, item?: string) => {
        // -1값 없애기
        selectedOption[0] === -1 && selectedOption.shift();

        if (selectedOption.includes(option)) {
            // 선택된 아이템 눌렀을 때 선택 해제
            setSelectedOption(selectedOption.filter((selectedItem) => selectedItem !== option));

            // 선택된 것이 없을 때 선택상태를 초기화
            (selectedOption.length <= 1 && startTypo[currentIndex].setDisabled) && setIsSelected(false);
        } else if (startTypo[pageIndex].purpose !== 'topic') {
            // 다중선택되는 페이지(1번 topic 페이지...)가 아니면 1개만 선택되도록 함
            setSelectedOption([option]);
            setIsSelected(true);
        } else {
            // 다중선택 페이지에서 3개 이상 선택되는 거 막기
            setSelectedOption([...selectedOption, option]);
            setIsSelected(true);
        }
        
        // dailyGoal 페이지에서 input값에 focus올렸을 시 선택상태 초기화
        if (item === '직접설정' || item === '0' || item === "NaN") {
            setIsSelected(false);
        }
    };

    // 다음 버튼 클릭 시
    const handleClickNext = async () => {
        // 현재 선택지의 이름
        const currentStep = startTypo[currentIndex].purpose;
        let currentValue: any[] = [];

        // 현재 선택지 값
        // 값이 하나일 경우 문자열로 저장하고, 여러 개일 경우 배열에 저장
        if (currentStep === 'dailyGoal') {
            // 일일목표를 직접 선택했을 때
            currentValue = [dailyGoal];
        } else if (selectedOption.length === 1) {
            currentValue = [startTypo[currentIndex].items[selectedOption[0]]];
        } else {
            currentValue = selectedOption.map(idx => idx !== -1 && startTypo[currentIndex].items[idx]);
        }

        // 선택지 저장
        setPurpose(prevPurpose => ({
            ...prevPurpose,
            [currentStep]: currentValue
        }));
        
        // topic저장할 때 topicGoals를 세팅
        if (currentStep === 'topic') {
            // 선택한 topic들을 topicGoals에 저장
            const newTopicGoals = currentValue.map((selectedTopic, index) => {
                // 선택한 topic의 topicId를 찾아서 저장
                const tempTopic = topics.find(topic => topic.topicName === selectedTopic);
                
                return {
                    topicId: tempTopic?.topicId || 0,
                    goal: '발전'
                };
            });

            // topic 페이지에서는 topicGoals 형식으로 저장
            setPurpose(prevPurpose => ({
                ...prevPurpose,
                topicGoals: newTopicGoals
            }));
        }

        // 다음 인덱스 계산 및 상태 업데이트
        const newIndex: number = (currentIndex + 1);
        setCurrentIndex(newIndex);

        // item index, 선택상태 초기화
        setSelectedOption([-1]);
        setIsSelected(!startTypo[newIndex].setDisabled);
    };


    // 학습목표 설정 완료 후 서버에 저장
    const handleClickStart = () => {
        // topicLevel 및 topic 키 제거
        const { topicLevel, topic, ...rest } = purpose;

        // 배열 길이가 1인 요소를 배열 밖으로 빼내는 함수
        const extractSingleElementArray = (obj: any): any => {
            for (const key in obj) {
                if (Array.isArray(obj[key]) && obj[key].length === 1) {
                    obj[key] = obj[key][0];
                }
            }
            return obj;
        };

        // 배열 길이가 1인 요소를 배열 밖으로 빼내기
        const result = extractSingleElementArray(rest);

        // 일일목표 퀴즈 수 설정
        dispatch(changeDailyGoal(result.dailyGoal));

        // 서버에 저장
        dispatch(postStudySettings(result));
        
        // 홈으로 이동
        navigate('/home');
    }

    // 직접설정 입력값 변경 시
    const handleInputChange = (newValue: number) => {
        if (newValue) {
            setDailyGoal(newValue);
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    }

    return (
        <PurposeSettingView
            currentIndex={currentIndex}
            selectedOption={selectedOption}
            handleClickStart={handleClickStart}
            handleClickNext={handleClickNext}
            handleOptionClick={handleOptionClick}
            handleChipClick={handleChipClick}
            isSelected={isSelected}
            purpose={purpose}
            dailyGoal={dailyGoal}
            handleInputChange={handleInputChange}
        />
    )
};

export default PurposeSettingContainer;