import { 
    Grid, Typography, Divider, TextField, Button, Link, Alert, 
} from "@mui/material";

import QuizAppBar from "components/QuizAppBar/QuizAppBar";
import { auth, sendPasswordResetEmail } from "firebase-config";
import useChangeBodyColor from "hooks/useChangeBodyColor";
import React, { useState } from "react";


/**
 * 비밀번호 찾기 화면
 */
const FindPassword: React.FC = () => { 
    const [email, setEmail] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    useChangeBodyColor('#FDFDFD');

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    // 이메일 입력 시
    const handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            await sendPasswordResetEmail(auth, email);
            setSuccessMsg('입력해주신 주소로 이메일을 발송했습니다!');
        } catch (error: any) {
            setErrMsg('등록되지 않은 이메일입니다.');
        }
    };

    return (
        <>
            <QuizAppBar navType="뒤로가기" navKeyword="뒤로가기" />
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ padding: '56px 16px 72px 16px' }}
            >
                <Grid item component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 , maxWidth: "512px"}}>
                    <Typography alignItems="left" variant="subtitle1" sx={{ fontSize: "24px", marginLeft: "0" }}>비밀번호 찾기</Typography>
                    <Typography alignItems="left" variant="body2">
                            StartupQT 회원가입에 사용한 이메일 주소를 입력해 주세요.<br/>
                            입력해 주신 이메일 주소로 비밀번호 재설정 링크를 보내드릴게요.
                    </Typography>
                    <Divider sx={{ margin: "24px 0 12px 0" }} />
                    {/* 이메일 input */}
                    <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="이메일"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleEmailChange}
                        disabled={successMsg ? true : false}
                    />

                    {/* 이메일 발송 실패 시 display */}
                    <Alert severity="error" style={{display: errMsg ? "flex" : "none", marginTop: "8px"}}>
                        {errMsg}
                    </Alert>

                    {/* 이메일 발송 성공 시 display */}
                    <Alert severity="success" style={{display: successMsg ? "flex" : "none", marginTop: "8px"}}>
                        {successMsg}
                    </Alert>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: "24px", mb: 2, height: "48px" }}
                    >
                        전송
                    </Button>
                    <Grid 
                        container 
                        width={"100%"}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <Link href="/login" variant="body2" underline="none" color="#707070">
                                {"로그인"}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" color="#707070">
                                |
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link href="/signup" variant="body2" underline="none" color="#707070">
                                {"회원가입"}
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default FindPassword;