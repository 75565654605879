import React, { useEffect, useState } from "react";
import SearchDetailView from "./SearchDetailView";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { searchQuiz, searchReference, searchTopic } from "redux/modules/API/getSearchResults";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ReferenceModel, SearchResultInterface, TopicModel } from "model/searchDetailModel";
import { QuizModel } from "model/QuizModel";
import scrollToTop from "utils/scrollToTop";
import useChangeBodyColor from "hooks/useChangeBodyColor";


const SearchDetailContainer: React.FC = () => {
    const { type, keyword } = useParams();  // 검색 타입(topic, quiz, reference)과 키워드
    const dispatch = useAppDispatch();  
    const [currentPage, setCurrentPage] = useState<number>(1); // 현재 페이지 번호
    const [contentType, setContentType] = useState<string>("");    // 검색결과의 타입 (토픽, 문제, 참고자료)
    const navigate = useNavigate();
    const loading = useAppSelector(state => {
        switch (type) {
          case "topic":
            return state.searchData.topicLoading;
          case "quiz":
            return state.searchData.quizLoading;
          case "reference":
            return state.searchData.referenceLoading;
          default:
            return "idle";
        }
      });

    // 검색 결과 상태 초기화
    const [searchResult, setSearchResult] = useState<SearchResultInterface<ReferenceModel[] | QuizModel[] | TopicModel[]>>({
        result: [],
        totalPages: 1,
        currentPage: 1,
    });

    // 페이지 변경 이벤트 핸들러
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
        scrollToTop();
        executeSearch(value);
    };

    // 검색 결과 페이지로 이동
    const goToSearchPage = (buttonText: string) => {
        navigate(`/search/${buttonText}`);
    };

    // content type에 따른 검색 실행 함수
    const executeSearch = async (searchPage: number) => {
        try {
            if (keyword === undefined) return;

            let searchResponse;
            switch (type) {
                case "topic":
                    setContentType("관련주제");
                    searchResponse = await dispatch(searchTopic({ pageNum: searchPage, pagingSize: 10, searchText: keyword }));
                    break;
                case "quiz":
                    setContentType("문제");
                    searchResponse = await dispatch(searchQuiz({ pageNum: searchPage, pagingSize: 10, searchText: keyword }));
                    break;
                case "reference":
                    setContentType("참고자료");
                    searchResponse = await dispatch(searchReference({ pageNum: searchPage, pagingSize: 10, searchText: keyword }));
                    break;
                default:
                    console.log("Invalid type");
                    return;
            }

            // 검색 결과 저장
            setSearchResult({
                result: searchResponse.payload.results.results,
                totalPages: searchResponse.payload.results.totalPages,
                currentPage: searchResponse.payload.results.currentPage,
            });
        } catch (error) {
            console.log(error);
        }
    };

    // 전역 배경색 설정
    useChangeBodyColor('white');

    // 검색 결과 초기화
    useEffect(() => {
        const auth = getAuth();

        const listener = onAuthStateChanged(auth, async (user) => {
            if (user) {
                user.getIdToken().then(async () => {
                     await executeSearch(1);
                });
            }
        });

        return () => {
            listener();
        };
    }, [keyword, type, dispatch]);


    return <SearchDetailView 
                loading={loading}
                contentType={contentType}
                searchKeyword={keyword}
                searchResult={searchResult}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                goToSearchPage={goToSearchPage}
                executeSearch={executeSearch}
            />;
};

export default SearchDetailContainer;