import React from 'react';
import QuizView from './QuizView';

import { useAppSelector, useAppDispatch } from "hooks/hooks";
import { increaseCount } from 'redux/modules/quizCouter';
import { turnToExplanation } from 'redux/modules/isQuiz';
import { useNavigate } from 'react-router-dom';
import { QuizModel } from 'model/QuizModel';
import { getOX, waitAndHideOXMarks } from 'redux/modules/cardList';
import { openModal } from 'redux/modules/modal';
import { setAchievementData } from 'redux/modules/userData';
import { sendQuizData } from 'redux/modules/API/sendQuizData';
import scrollToTop from 'utils/scrollToTop';


interface QuizProps {
  quizContent: QuizModel; // 퀴즈 데이터
  isHome?: boolean;       // 홈화면인지? (기본값: false)
  disableClick?: boolean; // 선지를 클릭했을 때 backgroundColor를 변경시킬지 (기본값: false) 
}

/*
* 문제가 들어있는 카드
*/
const QuizContainer: React.FC<QuizProps> = ({ quizContent, isHome = false, disableClick = false }) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSelected = useAppSelector((state) => state.cardList.isSelected);

  const cardList = useAppSelector((state) => state.cardList);
  const quizData = useAppSelector((state) => state.quizCounter);
  const userId = useAppSelector((state) => state.userAuth.user?.userId);

  const handleNavigate = async () => {
    // 푼 문제 수를 하나 늘림
    dispatch(increaseCount());

    // 현재상태 변수를 문제에서 해설로 바꾸기
    dispatch(turnToExplanation());

    // 정오 판단 및 OX 표시 애니메이션 출력
    dispatch(getOX(cardList.quizList[0]));
    dispatch(waitAndHideOXMarks());


    // 문제 이력 보내기
    const response = await dispatch(sendQuizData({
      playStartTime: quizData.playStartTime,
      selectExampleId: cardList.selectedIndex,
      isAnswer: cardList.isCorrect,
      quizId: cardList.quizList[0].quizId,
      userId: userId,
    }));
    
    // 새로운 업적이 있을 때 모달 띄우기
    const achievements = response.payload?.earnAchievementIdList;
    
    if(achievements!==undefined && achievements.length > 0) {
      // 업적 ID와 실제 업적 데이터 매칭하기
      dispatch(setAchievementData(achievements));

      // 업적모달 열기
      dispatch(
        openModal({
          modalType: "AchievementsModal",
        })
      );
    }

    // 해설화면으로 이동
    navigate("/explanation");
    scrollToTop();
  };

  const handleErrorReport = () => {
    dispatch(openModal({
      modalType: "ErrorReportModal",
    }));
  };

  return <QuizView
    quizContent={quizContent} isHome={isHome} disableClick={disableClick}
    handleNavigate={handleNavigate} handleErrorReport={handleErrorReport}
    isSelected={isSelected}
  />;
};

export default QuizContainer;