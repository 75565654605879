import React from "react";
import Grid from '@mui/material/Grid';
import Quiz from "components/Quiz";
import { Typography } from "@mui/material";
import TopAppBar from "components/TopAppBar/TopAppBar";
import NavBar from "components/NavBar";
import NoticeCard from "components/NoticeCard/NoticeCard";
import { QuizModel } from 'model/QuizModel';
import { UserType } from "model/UserModel";
import NoQuiz from "components/NoQuiz/NoQuiz";

interface HomeProps {
  curQuiz: QuizModel[];       // 현재 문제 데이터 
  user: (UserType | null);  // 현재 저장되어있는 유저 데이터
};

const HomeView:React.FC<HomeProps> = ({ curQuiz, user }) => {

  return (
    <>
      <TopAppBar/>
      <Grid  
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1.2}
        sx={{ padding: '56px 16px 72px 16px' }}
      >
        {/* 인스턴트 메세지 */}
        <Grid item>
          <Typography alignItems="left" variant="subtitle1" ml={1}>{user?.nickname && user?.nickname+"님, "} 오늘의 학습을 시작해볼까요?</Typography>
        </Grid>

        {/* 퀴즈 컴포넌트. 저장된 퀴즈가 없을 시 재로딩 컴포넌트 표시 */}
        {curQuiz.length < 1 ?
          <NoQuiz/>
        :   
          // 퀴즈(문제)
          <Grid item pb={2}>
            <Quiz quizContent={curQuiz[0]} isHome={true}/>
          </Grid>
        }


        {/* 홈 카드 */}
        <NoticeCard/>
        
      </Grid>
      <NavBar/>
    </>
  )
};

export default HomeView;