import { motion } from "framer-motion";

/**
 * OX(정답여부) 애니메이션 SVG 컴포넌트
 */

// OX 표시 애니메이션
const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i: number) => {
        const delay = 0.4 + i * 0.5;
        return {
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: { delay, type: "spring", duration: 0.5, bounce: 0 },
                opacity: { delay, duration: 0.01 }
            }
        };
    }
};


export const MotionOSVG = () => {
    return (
        <motion.svg
            width="88px"
            height="88px"
            initial="hidden"
            animate="visible"
            style={{
                background: '#fff',
                boxShadow: '0px 4px 24px 8px rgba(197, 197, 211, 0.42)',
                borderRadius: '32px'
            }}
        >
            <motion.circle
                cx="44"
                cy="44"
                r="24"
                stroke="#1268B3"
                variants={draw}
                custom={0}
                style={{ strokeWidth: '4px', fill: 'transparent', strokeLinecap: 'round' }}
            />
        </motion.svg>
    );
};


export const MotionXSVG = () => {
    return (
        <motion.svg
            width="88px"
            height="88px"
            initial="hidden"
            animate="visible"
            style={{
                background: '#fff',
                boxShadow: '0px 4px 24px 8px rgba(197, 197, 211, 0.42)',
                borderRadius: '32px'
            }}
        >
            <motion.line
                x1="24"
                y1="24"
                x2="64"
                y2="64"
                stroke="#d23a3a"
                variants={draw}
                custom={0}
                style={{ strokeWidth: '4px', strokeLinecap: 'round' }}
            />
            <motion.line
                x1="24"
                y1="64"
                x2="64"
                y2="24"
                stroke="#d23a3a"
                variants={draw}
                custom={0.5}
                style={{ strokeWidth: '4px', strokeLinecap: 'round' }}
            />
        </motion.svg>
    );
};