export type Migration = (state: any) => any;

const migrations: { [key: number]: Migration } = {
    0: (state) => {
        // 버전 0의 초기 상태 마이그레이션 로직
        return {
            ...state,
            migrated: true,
        };
    },
    3: (state) => {
        // 새롭게 추가된 속성들에 대한 마이그레이션 (StartupQT-알파)
        return {
            ...state,
            userData: {
                ...state.userData,
                // 새로운 필드 추가
                likeQuizIds: state.userData?.likeQuizIds || [],
                loading: state.userData?.loading || "idle",
            },
        };
    },
};

export const migrateState = async (state: any, currentVersion: number): Promise<any> => {
    if (!state) return Promise.resolve(undefined); // 초기 상태 설정
    let migratedState = state;

    // 현재 버전과 저장된 상태의 버전을 비교하여 마이그레이션 수행
    for (let i = state._persist.version + 1; i <= currentVersion; i++) {
        if (migrations[i]) {
            migratedState = migrations[i](migratedState);
        }
    }

    // 새로운 버전으로 상태 업데이트
    migratedState._persist.version = currentVersion;
    return Promise.resolve(migratedState);
};
