import React, { useEffect } from "react";

import { Typography } from "@mui/material";

import Quiz from "components/Quiz";
import { QuizModel } from 'model/QuizModel';
import { motion, useAnimation, PanInfo, Variants, useMotionValue } from "framer-motion";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';



interface ItemProps {
    index: number;              // 문제의 index. key로 사용됨. 0이면 현재 문제, 1이면 다음 문제
    onDelete: () => void;       // 해당 문제 카드가 왼쪽으로 스와이프 되었을 때 문제를 넘김처리하는 함수
    value: QuizModel;           // 문제 카드 안에 들어갈 문제 데이터
    currentCardRef: React.ForwardedRef<HTMLDivElement>; // 현재 문제카드 높이 ref
    padding: number;            // 문제카드 padding
    quizLength: number;         // 현재 로드되어있는 퀴즈의 갯수
}

/**
 * 현재, 다음 문제카드 각각에 대한
 * 문제카드 컴포넌트
 */
export default function QuizSearchItem({ index, onDelete, value, currentCardRef, padding, quizLength }: ItemProps) {
    const controls = useAnimation();                // 현재 문제 넘김 처리를 위함
    const innerControls = useAnimation();           // 현재 문제 드래그 중 넘김 확인 div의 투명도 조절을 위함.
    const outerXPosition = useMotionValue(0);       // 현재 문제의 x position 트래킹을 위함
    const dragStartXPosition = useMotionValue(0);   // 문제 카드의 트래킹 시작점(x축기준) 저장을 위함

    const nextQuizVariants: Variants = {
        // 다음 문제가 밑에서 천천히 올라오도록 함.
        initial: { y: 100, opacity: 0 },
        animate: { y: 0, opacity: 1 },
    };

    const handleDragStart = (_ : MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
        // drag시작한 x position을 저장해두기
        dragStartXPosition.set(info.point.x);
    };
    
    const handleDrag = (_ : MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
        // x축 방향으로 얼마나 움직였는지 확인
        outerXPosition.set(dragStartXPosition.get() - info.point.x);
    };

    const handleDragEnd = (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
        const offset = info.offset.x;       // x가 움직인 거리
        const velocity = info.velocity.x;   // x가 움직인 속도

        // 넘김 처리
        // 조건1. 사용자가 충분한 offset과 velocity로 왼쪽으로 스와이프함
        // 조건2. 현재 로드되어있는 퀴즈의 갯수가 2개 이상임(퀴즈가 1개일 때 pass 불가)
        if (offset < -150 && velocity < -500 && quizLength > 1) {
            // 넘김으로 판단 시 로직 수행
            controls.start({ x: "-500%", y: 0 }).then(() => setTimeout(() => onDelete(), 200));
        }else{
            // 넘김으로 판단되지 않았을 시 card 원위치
            controls.start({ x: 0 });

            // innerOpacity 원위치
            innerControls.start({ opacity: 0, transition: { duration: 0.3 }});
        }
    }


    const calculateOpacity = (position: number) => {
        const maxOpacity = 1;
        const minOpacity = 0;
        const opacity = (position - 40) * 0.01;
        
        if (position <= 0) {
            return 0;
        }

        return Math.max(minOpacity, Math.min(maxOpacity, opacity));
    };

    useEffect(()=>{
        outerXPosition.on("change", () => {
            const opacity = calculateOpacity(outerXPosition.get());

            innerControls.set({ opacity });
        });
    },[outerXPosition, innerControls]);

    return (
        <motion.div
            style={{
                padding: padding,
                willChange: "transform",
            }}
            layout
            transition={{ type: "spring", stiffness: 800, damping: 40 }}
        >
            {index === 0 ? (
                // 현재 문제
                <motion.div
                    drag="x"
                    dragDirectionLock
                    onDragStart={handleDragStart}
                    onDrag={handleDrag}
                    onDragEnd={handleDragEnd}
                    animate={controls}
                    ref ={currentCardRef}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            position: "relative",
                        }}
                    >
                        <Quiz quizContent={value}/>
                        <motion.div
                            animate={innerControls}
                            style={{
                                position: "absolute",
                                left: "110%",
                                top: "45%",
                                width: "50px",
                                opacity: 0,
                            }}
                        >
                            <NavigateBeforeIcon fontSize="large"/>
                            <Typography variant="body2">다음문제</Typography>
                        </motion.div>
                    </div>
                </motion.div>
            ) : (
                // 다음 문제
                <motion.div
                    variants={nextQuizVariants}
                    initial="initial"
                    animate="animate"
                    transition={{ duration: 0.5 }}
                    style={{position:"relative"}}
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            height: '100%',
                            width: '100%',
                            background: 'linear-gradient(to bottom, rgba(255, 255, 255, 1) 52px, rgba(255, 255, 255, 0) 28%)',
                            backdropFilter: 'blur(0.8px)',
                            zIndex: '5',
                            borderRadius: '24px',
                            opacity: 1 // 투명도 설정
                        }}
                    >
                        <Typography width={"100%"} alignItems="left" variant="subtitle1" pl={2} mt={3}>다음 문제</Typography>
                    </div>
                    <motion.div
                        drag="y"
                        dragDirectionLock
                    >
                        <Quiz quizContent={value} disableClick={true}/>
                    </motion.div>
                </motion.div>
            )}
        </motion.div>
    );
}