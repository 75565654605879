import React, { useRef, useEffect, useState } from "react";
import QuizPageView from "./QuizPageView";
import { passQuiz } from "redux/modules/cardList";
import { QuizModel } from 'model/QuizModel';
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useMotionValue } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { fetchQuiz } from "redux/modules/API/fetchQuiz";
import { sendQuizData } from "redux/modules/API/sendQuizData";
import { resetTime } from "redux/modules/quizCouter";
import { shiftFailedRequest } from "redux/modules/internetConnect";

const height: number = 168;
const padding: number = 16;


function useConstraints(currentCardHeight: number): [constraints: { top: number; bottom: number }, totalHeight: number] {
    const [constraints, setConstraints] = useState({ top: 0, bottom: 0 });
    const [totalHeight, setTotalHeight] = useState(currentCardHeight + height + padding);

    // app Bar Height, 상단 Typography Height, 2번째카드에서 노출되는 height(변경가능)
    const upperContainers = 56 + 92 + 240;

    // 콘텐츠가 들어갈 containter의 높이
    const contentContainer = window.innerHeight - upperContainers;

    useEffect(() => {
        const contentHeight = currentCardHeight + height + padding;

        if (currentCardHeight > contentContainer) {
            // 콘텐츠가 들어갈 높이보다 card높이가 길면 삐져나간 부분만큼 스크롤이 가능하게 함
            setConstraints({ top: contentContainer - currentCardHeight, bottom: 0 });
            setTotalHeight(contentHeight);
        } else {
            // 콘텐츠가 들어갈 높이보다 card높이가 짧으면 위로 스크롤할 수 없도록.
            setConstraints({ top: 0, bottom: 0 });
            
            if (contentContainer > contentHeight) {
                setTotalHeight(contentHeight + (contentContainer - contentHeight));
            }
        }

        if (totalHeight < window.innerHeight){
            setTotalHeight(window.innerHeight - 56 - 92);
        }
    }, [currentCardHeight]);

    return [constraints, totalHeight];
}

const QuizPageContainer: React.FC = () => {
    const quizList: QuizModel[] = useAppSelector(state => state.cardList.quizList);
    const failedRequest = useAppSelector(state => state.internetConnect.failedRequest);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // 현재 퀴즈의 카드 높이
    const [currentCardHeight, setCurrentCardHeight] = useState(0);
    const currentCardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // 현재 문제카드의 높이 설정
        if (currentCardRef.current) {
            setCurrentCardHeight(currentCardRef.current?.clientHeight);
        };
    }, [quizList]);

    // 스크롤을 위한 y변수
    const y = useMotionValue(0);

    // 현 퀴즈의 카드 높이에 따라 스크롤 제한
    const { top, bottom } = useConstraints(currentCardHeight)[0];
    const totalHeight = useConstraints(currentCardHeight)[1];
    

    // 문제를 넘겼을 때
    const onDelete = async() => {
        try {
            dispatch(passQuiz());

            // 문제 풀기 시작한 시간 초기화
            dispatch(resetTime());

            await dispatch(fetchQuiz());
        } catch (error) {
            alert('문제 받아오기에 실패했습니다. 홈으로 이동합니다.');
            navigate('/');
        }
    };


    /**
     * 인터넷 연결 관리
     */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
  
    
    useEffect(() => {
        // 인터넷이 연결되어있을 때
        if(navigator.onLine) {
            // 문제가 2개 미만일 때 문제를 새로 가져옴
            if (quizList.length < 2) {
                dispatch(fetchQuiz());
            };

            // 실패한 요청이 있을 때 다시 요청
            if (failedRequest.length > 0) {
                // 한 번에 3개까지만 요청을 보내도록 함.
                const getThreeItems = failedRequest.slice(0, 3);
                getThreeItems.map(async (item: any) => {
                    dispatch(shiftFailedRequest())
                    await dispatch(sendQuizData(item));
                });
            };
        };


        // 인터넷 연결 상태 변경 이벤트 리스너 등록
        window.addEventListener('online', handleOnlineStatusChange);
        window.addEventListener('offline', handleOnlineStatusChange);
        
        return () => {
            window.removeEventListener('online', handleOnlineStatusChange);
            window.removeEventListener('offline', handleOnlineStatusChange);
        };
    }, []);
  
  
    // 인터넷 연결 상태 변경을 감지하고 액션을 디스패치하는 함수
    const handleOnlineStatusChange = async() => {
      if (navigator.onLine) {
        setSnackbarMessage('연결되었습니다.');
        setSnackbarOpen(true);

        // 다음문제가 로드되어있지 않으면 문제를 새로 가져오도록 함.
        if (quizList.length < 2) {
            await dispatch(fetchQuiz());
        };
      } else {
        setSnackbarMessage('인터넷 연결이 끊겼습니다.');
        setSnackbarOpen(true);
      }
    };
  
    // 스낵바 닫힘 처리
    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSnackbarOpen(false);
    };


    return (
        <>
            <QuizPageView
                currentCardRef={currentCardRef}
                quizList={quizList}
                top={top}
                bottom={bottom}
                y={y}
                onDelete={onDelete}
                height={totalHeight}
                padding={padding}

                // 스낵바
                snackbarOpen={snackbarOpen}
                handleSnackbarClose={handleSnackbarClose} 
                snackbarMessage={snackbarMessage} 
            />
        </>
    )
};

export default QuizPageContainer;