import React from "react";

import Grid from '@mui/material/Grid';
import { Card, CardContent, Typography } from "@mui/material";

import Quiz from "components/Quiz";
import QuizNav from "components/QuizNav/QuizNav";
import QuizAppBar from "components/QuizAppBar/QuizAppBar";
import CustomMarkdown from "components/CustomMarkdown/CustomMarkdown";
import { QuizModel } from "model/QuizModel";
import { useAppSelector } from "hooks/hooks";
import useChangeBodyColor from "hooks/useChangeBodyColor";

interface ExplanationProps {
    TypoOX: string | undefined;         // 사용자가 푼 문제가 정답인지 알려주는 typography
    description: string | undefined;    // mark down으로 이루어진 해설
    curQuiz: QuizModel;                 // 현재 퀴즈 데이터
    keyword?: string;                   // 검색 키워드
};

const ExplanationPageView: React.FC<ExplanationProps> = ({TypoOX, description, curQuiz, keyword}) => {
    const isCorrect = useAppSelector(state => state.cardList.isCorrect);
    useChangeBodyColor(isCorrect ? '#f1faf1' : '#fceff1');
    
    return (
        <>  
            {
                keyword ? 
                <QuizAppBar navType="탐색" navKeyword={keyword}/> :
                <QuizAppBar navType="종료하기" navKeyword="종료하기"/>
            }
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ padding: '56px 0 184px 0' }}
            >
                {/* 문제 정오 및 상태를 나타내는 Typo */}
                <Grid item
                    style={{
                        zIndex: 6,
                        paddingLeft: "4px",
                    }} 
                >
                    <Typography alignItems="left" variant="subtitle1">{TypoOX}</Typography>
                </Grid>

                {/* 해설 */}
                <Grid item>
                    <Card >
                        <CardContent>
                            <Typography alignItems="left" variant="body1" sx={{ p: '2px 0 6px 0' }}>✨해설</Typography>
                            <CustomMarkdown content={description}/>
                        </CardContent>
                    </Card>
                </Grid>

                {/* 푼 문제 */}
                <Grid item>
                    <Quiz quizContent={curQuiz}/>
                </Grid>


            </Grid>
            <QuizNav/>
        </>
    );
};

export default ExplanationPageView;