import { Card, CardContent, Divider, Grid, Pagination, Typography } from "@mui/material";
import ButtonWithBackground from "components/ButtonWithBackground/ButtonWithBackground";
import CustomMarkdown from "components/CustomMarkdown/CustomMarkdown";
import Loading from "components/Loading/Loading";
import QuizAppBar from "components/QuizAppBar/QuizAppBar";
import QuizArticle from "components/QuizArticle/QuizArticle";
import Title from "components/Title/Title";
import { QuizModel } from "model/QuizModel";
import { ReferenceModel, SearchResultInterface, TopicModel } from "model/searchDetailModel";
import React from "react";

interface SearchDetailContainerProps {
    loading: "idle" | "pending" | "succeeded" | "failed"; // 로딩 상태
    searchKeyword: string | undefined; // 검색한 키워드
    contentType: string; // 검색 결과의 타입 (토픽, 문제, 참고자료)
    searchResult: SearchResultInterface<ReferenceModel[] | QuizModel[] | TopicModel[]>; // 검색 결과
    currentPage: number; // 현재 페이지 번호
    handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void; // 페이지 변경 이벤트 핸들러
    goToSearchPage: (buttonText: string) => void; // 검색 페이지로 이동
    executeSearch: (searchPage: number) => Promise<void>; // content type에 따른 검색 실행 함수
};

const SearchDetailView: React.FC<SearchDetailContainerProps> = ({ loading, contentType, searchKeyword, searchResult, currentPage, handlePageChange, goToSearchPage, executeSearch }) => {

    // 세부 검색 결과 컴포넌트
    const SearchDetailContent = () => {
        // 로딩 중 또는 로딩 실패 시
        if (loading !== "succeeded") {
            return (
                <Loading
                    loading={loading}
                    loadingMessage="검색 중입니다..."
                    failedMessage="검색에 실패했습니다."
                    action={() => executeSearch(currentPage)}
                />
            );
        }

        // 검색 결과가 없을 때
        if (searchResult.result.length === 0) {
            return <Typography variant="body2" color={'#909090'} sx={{ pt: 2, pb: 4 }}>검색 결과가 없습니다.</Typography>;
        }

        // 검색 결과가 성공적으로 로딩된 경우
        if (contentType === "문제") {
            return (
                <>
                    { searchResult.result.map((quiz: any, index: number) => (
                        <Card key={index} sx={{ pb: '14px', mb: 2 }} onClick={() => { }}>
                            <QuizArticle quizItems={quiz} isClickable={true} currentUrl={`/search/quiz/${searchKeyword}`}/>
                        </Card>
                    ))}
                </>
            )
        } else if (contentType === "관련주제") {
            const buttonsContent = searchResult.result.map((result: any) => result.topicName);
            return <ButtonWithBackground text={buttonsContent} onClick={goToSearchPage} />;

        } else if (contentType === "참고자료") {
            return (
                <>
                    { searchResult.result.map((reference: any, index: number) => (
                        <Card key={index} sx={{ mb: 2 }}>
                            <CardContent>
                                <CustomMarkdown content={reference.referenceData} />
                            </CardContent>
                        </Card>
                    )) }
                </>
            );
        } else {
            return <></>;
        }
    
    }



    return (
        <div>
            <QuizAppBar navType="탐색" navKeyword={searchKeyword} />
            <Grid
                pt={4}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100%' }}
            >
                <Title title="검색 결과" description={`"${searchKeyword}" 검색 결과입니다.`} />
                <Divider sx={{ width: '100%' }} />

                {/* 검색 결과 */}
                <Title title={contentType} count={searchResult.result.length} />
                <SearchDetailContent />

                <Pagination count={searchResult.totalPages} page={currentPage} onChange={handlePageChange} sx={{ m: '16px 0' }} />
            </Grid>
        </div>
    );
};

export default SearchDetailView;