import React from "react";
import SettingsView from "./SettingsView";
import { openModal } from "redux/modules/modal";
import { useAppDispatch } from "hooks/hooks";
import useChangeBodyColor from "hooks/useChangeBodyColor";

const LoginContainer:React.FC = () => {
    const dispatch = useAppDispatch();

    // 전역 배경색 설정
    useChangeBodyColor('white');

    const handleLogout = () => {
        dispatch(
            openModal({
                modalType: "LogoutModal",
            })
        );
    };

    const handleQuit = () => {
        dispatch(
            openModal({
                modalType: "QuitModal",
            })
        );
    }

    return(
        <SettingsView 
            handleLogout={handleLogout}
            handleQuit={handleQuit}
        />
    );
};

export default LoginContainer;