import { useEffect, useState } from "react";
import MYDetailView from "./MYDetailView";
import { getQuizPlayed, getWrongQuiz } from "redux/modules/API/getSearchResults";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useParams } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { SearchResultInterface } from "model/searchDetailModel";
import { getAchievements } from "redux/modules/API/getAchievements";
import { mergedAchievementType } from "model/UserDataModel";
import scrollToTop from "utils/scrollToTop";
import useChangeBodyColor from "hooks/useChangeBodyColor";

const MYDetailContainer: React.FC = () => {
    const dispatch = useAppDispatch();
    const { type } = useParams();

    const [currentPage, setCurrentPage] = useState<number>(1); // 현재 페이지 번호
    const [contentType, setContentType] = useState<string>("");    // 검색결과의 타입 (토픽, 문제, 참고자료)

    const achievementData: mergedAchievementType[] | null = useAppSelector((state) => state.myPageData.achievements);
    
    // 검색 결과 상태 초기화
    const [searchResult, setSearchResult] = useState<SearchResultInterface<mergedAchievementType[]>>({
        result: [],
        totalPages: 1,
        currentPage: 1,
    });

    // 페이지 변경 이벤트 핸들러
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
        scrollToTop();
        executeSearch(value);
    };


    // content type에 따른 검색 실행 함수
    const executeSearch = async (searchPage: number) => {
        try {
            if (type === undefined) return;

            let searchResponse;
            switch (type) {
                // 업적 상세 페이지
                case "achievements":
                    setContentType("업적");
                    searchResponse = await dispatch(getAchievements({pageNum: searchPage, pagingSize: 36}));
                    
                    // 검색 결과 저장
                    setSearchResult({
                        result: achievementData ? achievementData : [],
                        totalPages: searchResponse.payload.maxPageNum,
                        currentPage: searchResponse.payload.currentPageNum,
                    });
                    break;

                // 풀었던 문제 상세 페이지
                case "played":
                    setContentType("풀었던 문제");

                    // 데이터를 받아온 뒤 문제만 추출
                    searchResponse = await dispatch(getQuizPlayed());
                    if(searchResponse.payload === undefined) break;
                    searchResponse = searchResponse.payload.representQuiz.reduce((acc:any, cur:any) => {
                        return acc.concat(cur.quizList);
                      }, []);

                    setSearchResult({
                        ...searchResult,
                        result: searchResponse ? searchResponse : [],
                    });

                    break;

                // 틀린 문제 상세 페이지
                case "mistake":
                    setContentType("틀린 문제");
                    searchResponse = await dispatch(getWrongQuiz());
                    console.log(searchResponse);
                    searchResponse = searchResponse.payload.representQuiz.reduce((acc:any, cur:any) => {
                        return acc.concat(cur.quizList);
                    }, []);

                    setSearchResult({
                        ...searchResult,
                        result: searchResponse ? searchResponse : [],
                    });
                    break;
                default:
                    console.log("Invalid type");
                    return;
            }


        } catch (error) {
            console.log(error);
        }
    };

    // 전역 배경색 설정
    useChangeBodyColor('white');

    // 검색 결과 초기화
    useEffect(() => {
        const auth = getAuth();

        const listener = onAuthStateChanged(auth, async (user) => {
            if (user) {
                user.getIdToken().then(async () => {
                    await executeSearch(1);
                });
            }
        });

        return () => {
            listener();
        };
    }, [type, dispatch]);

    return (
        <MYDetailView
            contentType={contentType}
            searchResult={searchResult}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            executeSearch={executeSearch}
        />
    );
};

export default MYDetailContainer;